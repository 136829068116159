// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

svg.bg-primary-2 * {
  //fill: #427894;
}

footer {
  padding: 1rem 0;
}

$dark-color: #1e292b;

section.section-services {
  background-color: $dark-color;
}

section.section-technology {
  background-color: $dark-color;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.icon-technology {
  flex-shrink: 0;
  width: auto;
  height: $spacer;
  &.icon-md{
    height: $spacer*1.5;
  }
  &.icon-lg {
    height: $spacer*2;
  }
  &.icon-sm {
    height: $spacer/2;
  }
}
